import { UseInfiniteScroll } from "../../hooks/useEventsInfiniteLoader";
import styles from "./styles/index.module.scss";
import Spinner from "../Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDotCircle
} from "@fortawesome/free-solid-svg-icons";

type LoaderProps = Pick<
  UseInfiniteScroll,
  "isLoading" | "loadMoreCallback" | "isLastPage"
>;

export default function InfiniteLoader({
  isLoading,
  isLastPage,
  loadMoreCallback,
}: LoaderProps) {
  if (isLoading) return <div className={styles.spinner}><Spinner /></div>;

  if (isLastPage) {
    return (
      <div className={styles.spinner}>
        <FontAwesomeIcon icon={faDotCircle} />
      </div>
    );
  }

  return <div className={styles.observerTarget} ref={loadMoreCallback}></div>;
}