import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../style_sheets/createKey.scss';
const CreateKey: React.FC = () => {
  const [email, setEmail] = useState("");

  const createKey = () => {
    console.log(email);
  }

  return (
    <div className='container'>
      <div className='createKey'>
        <div className='page-title'>Create Key</div>
        <div className='page-description'>
            <div>Sanctum can create a secure key for use with <b>Nostr</b> apps, or you can import your own if you already have one.</div>
            <div className='marked'>Need help?</div>
        </div>
        <hr className='hr-line'/>
        <div className='emailed'>
            <p className='content-title'>Get a login code emailed to you</p>
            <div className='input-email'>
                <input 
                    type="text" 
                    placeholder="jhilton@shock.network" 
                    onChange={e => setEmail(e.target.value)} 
                />
            </div>
            <div className='send-btn'>
                <button onClick={createKey}>Create Key</button>
            </div>
        </div>
        <div className='hr-line-text'>or</div>
        <div className='ask-isNSEC'><Link className='marked' to="/nsec">Already have an NSEC for Nostr?</Link></div>
      </div>
    </div>
  );
}

export default CreateKey;
