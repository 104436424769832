import React from "react";
import '../../style_sheets/footer.scss';

export const Footer = () => {

    return (
        <div className='footer'>
            By proceeding you acknowledge that this is bleeding-edge software, and agree to the providers <span className="marked">terms</span> regarding any services herein.
        </div>
    )
}
