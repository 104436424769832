import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../style_sheets/confirmation.scss";
import Select from "../../components/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../redux";
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import client from "../../client";
import * as Types from "../../codegen/types";
import useAuth from "../../hooks/useAuth";
import { fetchUserData } from "../../redux/userSlice";
import { nip19 } from "nostr-tools";
import { getSndLevelDomain } from "../../utils/utility";
import UserAgentIcons from "../../components/UserAgentIcons";

const Confirmation: React.FC = () => {
  useAuth(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [requestTokenData, setRequestTokenData] = useState<
    (Types.GetRequestTokenDataResponse & { requestToken: string }) | null
  >(null);
  const [selectedSlot, setSelectedSlot] = useState(1);

  useEffect(() => {
    const { requestToken } = location.state as { requestToken: string };
    if (!requestToken) {
      toast.error(
        <Toast
          title="Request Token Error"
          message="No request token was provided"
        />
      );
      navigate("/");
      return;
    }
    client.GetRequestTokenData({ requestToken }).then((res) => {
      if (res.status === "ERROR") {
        toast.error(<Toast title="Request Token Error" message={res.reason} />);
        navigate("/");
        return;
      }

      setRequestTokenData({
        domain: res.domain,
        user_agent: res.user_agent,
        requestToken,
      });
    });
  }, [user, navigate, location]);

  const userKeys = useMemo(() => {
    const keys = Object.entries(user.userInfo.keySlots).map(([key, slot]) => ({
      title: nip19.npubEncode(key).substring(0, 27) + "...",
      value: slot,
    }));
    return keys;
  }, [user]);

  const handleConfirm = useCallback(async () => {
    if (!requestTokenData) return;

    const res = await client.AuthorizeRequestToken({
      request_token: requestTokenData.requestToken,
      key_slot: selectedSlot,
    });
    if (res.status === "ERROR") {
      toast.error(<Toast title="Error" message={res.reason} />);
      return;
    }
    dispatch(fetchUserData());
    toast.success(
      <Toast
        title="Success"
        message={`Access Token successfuly created for key slot ${selectedSlot}`}
      />
    );
    navigate("/manage");
  }, [navigate, requestTokenData, selectedSlot, dispatch]);

  return (
    <div className="container confirmation">
      <div className="title">Confirm</div>
      {requestTokenData === null ? (
        <div>loading...</div>
      ) : (
        <>
          <div className="top-tip">
            An app, identifying itself as{" "}
            {getSndLevelDomain(requestTokenData.domain)} from a{" "}
            <UserAgentIcons ua={requestTokenData.user_agent} /> user agent, is
            requesting access to use your Nostr keys
          </div>
          <Select
            value={selectedSlot}
            onChange={(slot: number) => setSelectedSlot(slot)}
            label="Slot"
            items={userKeys}
          />
          <div className="check">
            <img src="/check.svg" alt="check" width={24} height={24} />
            Sign and encrypt events
          </div>
          <div className="check">
            <img src="/check.svg" alt="check" width={24} height={24} />
            Decrypt events
          </div>
          <div className="bottom-tip">
            <div>
              Your private key will not be shared and you may revoke access at
              any time, but this app may impersonate you until then.
            </div>{" "}
            <br />
            Do you trust this app/device?
          </div>
          <div className="button-group">
            <button className="button-outline" onClick={() => navigate("/")}>
              Deny
            </button>
            <button className="button" onClick={handleConfirm}>
              Allow
            </button>
          </div>
          <div className="readme">
            By proceeding you acknowledge that this is bleeding-edge software,
            and agree to the providers <span className="active">terms</span>{" "}
            regarding any services herein.
          </div>
        </>
      )}
    </div>
  );
};

export default Confirmation;
