import '../../style_sheets/modal.scss';
import ReactDOM from 'react-dom';

interface ModalProps {
	header: string;
	children: JSX.Element;
	hide: () => void;
}

export const Modal = ({
	header,
	children,
	hide
}: ModalProps) => {



	const jsx =
		<>
			<div className="modal-wrapper" onClick={() => hide()}>
			</div>
			<div className="modal-container">
				<div className="modal-header">
					{header}
				</div>
				<div className="modal-content">
					{children}
				</div>
			</div>
		</>


	return ReactDOM.createPortal(jsx, document.body);

};
