import { Modal } from "../Modal";
import * as Types from "../../codegen/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormatEventToDisplayEvent,
  formatTimestamp,
  getSndLevelDomain,
  truncateStringEnd,
} from "../../utils/utility";
import { nip19 } from "nostr-tools";
import client from "../../client";
import { useDispatch, useSelector } from "../../redux";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { fetchUserData } from "../../redux/userSlice";
import Events from "../Events";
import { useNavigate } from "react-router-dom";
import { DisplayEvent } from "../../utils/types";
import * as Icon from "../../iconLibrary/svgIconLibrary";

interface Props {
  info: Types.AccessTokenInfo;
  hide: () => void;
}

const AccessTokenModal = ({ info, hide }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [events, setEvents] = useState<DisplayEvent[]>([]);

  useEffect(() => {
    client
      .GetAccessTokenRecords({
        access_token: info.access_token,
        max: 5,
        page: 1,
      })
      .then((res) => {
        if (res.status !== "OK") {
          throw new Error(res.reason);
        }
        FormatEventToDisplayEvent(res.records).then((formattedEvents) => {
          setEvents(formattedEvents);
        });
      });
  }, [info]);

  const formattedInfo = useMemo(() => {
    const created = formatTimestamp(info.created_at);

    // origin name
    const label = getSndLevelDomain(info.origin);

    // npub encoding
    const npub = nip19.npubEncode(info.public_key);

    return {
      created,
      label,
      npub: truncateStringEnd(npub),
    };
  }, [info]);

  // !!20240809pm3
  const [label, setLabel] = useState(formattedInfo.label);
  const [is_edit, setIs_edit] = useState(false);
  const [changeLabel, setChangeLabel] = useState("");

  const edit_label = (value : string) => {
    setChangeLabel(value)
  }

  const Application_label = () => {
    if(!is_edit){
      return (
        <>
        <div className="value marked" onClick={()=>{setIs_edit(true)}}>
          {Icon.pencil_icon()}
          <div className="value marked">{formattedInfo.label}</div>
        </div>
        </>
      );
    }else{
      return (
        <div className="edit_application">
          <input
            type="text"
            placeholder="Enter Label"
            value={changeLabel}
            onChange={(e) => edit_label(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key == "Enter"){
                setLabel(changeLabel);
                setChangeLabel("");
                setIs_edit(false);
              }
            }}
          />
          <div className="crt_application_labels">
            <div
              onClick={() => {
                setChangeLabel("");
                setIs_edit(false);
              }}
            >
              {label}
            </div>
          </div>
        </div>
      );
    }
  }

  const cancel_change_label = () => {
    setLabel(label);
  }

  const handleRevokeToken = useCallback(async () => {
    const revoke = window.confirm(
      "Are you sure you want to delete this access token?"
    );
    if (revoke) {
      const res = await client.DeleteAccessToken({
        access_token: info.access_token,
      });
      if (res.status !== "OK") {
        toast.error(
          <Toast title="Error revoking access token" message={res.reason} />
        );
        return;
      }
      dispatch(fetchUserData());
      hide();
    }
  }, [info, dispatch, hide]);

  return (
    <Modal header={`Client_ID-${info.client_key}`} hide={hide}>
      <div className="access-token-modal">
        <div className="info-rows-container">
          <div className="info-row">
            <div className="label">Created</div>
            <div className="value">{formattedInfo.created}</div>
          </div>
          <div className="info-row">
            <div className="label">Application Label</div>
            {Application_label()}
          </div>
          <div className="info-row">
            <div className="label">
              Key Slot {userInfo.keySlots[info.public_key]}
            </div>
            <div className="value">{formattedInfo.npub}</div>
          </div>
          <div className="info-row">
            <div className="label">User Agent</div>
            <div className="value">{info.user_agent.substring(0, 9)}</div>
          </div>
          <div className="info-row">
            <div className="label">Latest Events</div>
            <div className="value"></div>
          </div>
        </div>
        <Events events={events} />

        <div
          className="full-list-text"
          onClick={() => {
            hide();
            navigate("/events", { state: { accessToken: info.access_token } });
          }}
        >
          Audit Log
        </div>

        <div className="buttons-container">
          <button className="revoke-button" onClick={handleRevokeToken}>
            Revoke
          </button>
          <button onClick={() => hide()}>Save</button>
        </div>
      </div>
    </Modal>
  );
};

export default AccessTokenModal;
