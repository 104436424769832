import React, { useEffect } from "react";

import { LayoutProps } from "./types";
import { Header } from "../containers/header";
import { Footer } from "../containers/footer";
import { Outlet } from "react-router-dom";

export const Layout: React.FC<LayoutProps> = ({ children }): JSX.Element => {

  return (
    <div className="main-layout">
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout;
