import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import "../../style_sheets/home.scss";
import { useSelector } from "../../redux";

const App: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useSelector((state) => state.user);

  useEffect(() => {
    if (session) {
      navigate("/manage");
    }
  }, [session, navigate]);

  return (
    <div className="container">
      <div className="home">
        <div className="page-title">Welcome</div>
        <div className="page-description">
          <div>
            Sanctum makes authentication and key management simple and secure
            for users of <b>Nostr</b> apps.
          </div>
          <Link to="/learn" className="marked">
            Learn More
          </Link>
        </div>
        <hr className="hr-line" />
        <div className="emailed">
          <button className="button" onClick={() => navigate("/authenticate")}>
            Get Started
          </button>
        </div>
        <div className="hr-line-text">or</div>
        <div className="ask-isNSEC">
          <Link className="marked" to="/nsec">
            Already have an NSEC for Nostr?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default App;
