import React, { useState } from 'react';
import * as Types from '../../globalTypes';
import * as Icons from '../../iconLibrary/svgIconLibrary';

interface ServiceCardProps extends Types.ServiceCardProps {
  selectService: (card: Types.ServiceCardProps) => void;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({ id, icon, name, price, description, status, selectService }) => {

  return (
    <div className='service-card' onClick={() => selectService({ id, icon, name, price, description })}>
      <input type="radio" name="serviceCard" id={id.toString()} />
      <div className='icon marked'>{icon}</div>
      <div className='name'>{name}</div>
      <div className='price marked'>
        {price}
        {status && <div>{status}</div>}
      </div>
    </div>
  );
}

interface ServiceDetailsCardProps {
  selectedService: string | Types.ServiceCardProps;
  close: () => void;
}

export const ServiceDetailsCard: React.FC<ServiceDetailsCardProps> = ({ selectedService, close }) => {

  return (
    <div className='service-details'>
      <div className='icon-close' onClick={() => close()}>{Icons.close()}</div>
      <div className='service-icon'>{typeof selectedService === 'string' ? '' : selectedService.icon}</div>
      <div className='service-name'>{typeof selectedService === 'string' ? '' : selectedService.name}</div>
      <div className='service-description'>
        <div>{typeof selectedService === 'string' ? selectedService : selectedService.description.map((d, i) => <div key={i}>{d}</div>)}</div>
      </div>
      <div className='service-price marked'>{typeof selectedService === 'string' ? '' : `${selectedService.price}${selectedService.name==='Basic' ? '' : ', for his life'}`}</div>
    </div>
  );
}