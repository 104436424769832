import React, { useMemo, useState } from "react";
import "../../style_sheets/manage.scss";
import { useSelector } from "../../redux";
import { formatTimestamp, getSndLevelDomain } from "../../utils/utility";
import useAuth from "../../hooks/useAuth";
import UserAgentIcons from "../../components/UserAgentIcons";
import * as Types from "../../codegen/types";
import AccessTokenModal from "../../components/accessTokenModal";

const Manage: React.FC = () => {
  useAuth(true);
  const { userInfo } = useSelector((state) => state.user);
  const [selectedModal, setSelectedModal] =
    useState<Types.AccessTokenInfo | null>(null);

  const tableRows = useMemo(() => {
    return (
      <>
        {userInfo.accessTokens.map((t) => {
          return (
            <tr key={t.access_token}>
              <td className="slot">{userInfo.keySlots[t.public_key]}</td>
              <td className="label" onClick={() => setSelectedModal(t)}>
                {getSndLevelDomain(t.origin)}
              </td>
              <td>
                <span>
                  <UserAgentIcons ua={t.user_agent} />
                </span>
              </td>
              <td className="date">
                {t.last_used === 0
                  ? "Hasn't been used yet"
                  : formatTimestamp(t.last_used)}
              </td>
            </tr>
          );
        })}
      </>
    );
  }, [userInfo]);

  return (
    <div className="container manage">
      <div className="title">Token Management</div>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>Slot</th>
              <th>Label</th>
              <th>Agent</th>
              <th>Last</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
      {selectedModal !== null && (
        <AccessTokenModal
          info={selectedModal}
          hide={() => setSelectedModal(null)}
        />
      )}
    </div>
  );
};

export default Manage;
