import { useEffect, useRef, useState } from "react";
import "./style.scss";

interface Props<T> {
  label?: string;
  value?: T;
  onChange?: (value: T) => void;
  items: { value: T; title: string }[];
}

export default function Select<T>(props: Props<T>) {
  const {
    label,
    items,
    value,
    onChange = (value: T) => { },
  } = props;
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickListener = (e: globalThis.MouseEvent) => {
      const box = bodyRef.current;
      if (!box) return;
      if (!box.contains(e.target as Node)) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener("click", clickListener);
    return () => window.removeEventListener("click", clickListener);
  }, []);

  const seletedItem = items.find((item) => item.value === value);

  return (
    <div className="select-container" ref={bodyRef}>
      <span className="label">{label}</span>
      <div
        className="selectgroup"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <div className="select">
          <div className="selectout">
            {seletedItem?.title ?? "Please select"}
            {/* <img src="/Rectangle.svg" alt="avatar" width={10} height={5} /> */}
          </div>
          <div>
            <img src="/Path.svg" alt="triangle" width={10} height={5} />
          </div>
        </div>
        <div className="dropdown" data-visible={dropdownVisible}>
          {items.map((item, index) => (
            <div key={index} onClick={() => onChange(item.value)}>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
