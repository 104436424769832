import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import { isValidEmail } from '../../utils/utility';
import '../../style_sheets/home.scss';
import client from '../../client';
import useAuth from '../../hooks/useAuth';
import { useSelector } from '../../redux';

export enum AuthType {
	SIGNUP = "Sign Up",
	LOGIN = "Log In"
}

const isAuthType = (param: string | null): param is AuthType => {
	return !!param && Object.values(AuthType).map(a => a.toString()).includes(param);
}



const Authenticate: React.FC = () => {
	useAuth(false);
	const location = useLocation()
	const navigate = useNavigate();
	const { session } = useSelector(state => state.user);
	const [requestToken, setRequestToken] = useState<string | undefined>();
	const [identifier, setIdentifier] = useState<string | undefined>();
	const [nsec, setNsec] = useState<string | undefined>();

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const r = urlParams.get("requestToken");
		const i = urlParams.get("identifier");
		const n = urlParams.get("nsec");
		setRequestToken(r || undefined)
		setIdentifier(i || undefined)
		setNsec(n || undefined);
		if (r) {
			if (session) {
				navigate("/confirmation", {
					state: { requestToken: r }
				});
			}
		}

	}, [navigate, session, location]);


	const [email, setEmail] = useState("");

	const urlParams = new URLSearchParams(location.search);
	const initialParam = urlParams.get("authType")

	const authType = isAuthType(initialParam) ? initialParam : AuthType.SIGNUP;



	const handleAuthTypeChange = (type: AuthType) => {
		const requestTokenQuery = requestToken ? `requestToken=${requestToken}&` : '';
		const identifierQuery = identifier ? `identifier=${identifier}&` : '';
		const nsecQuery = nsec ? `nsec=${nsec}&` : ''
		navigate(`?${requestTokenQuery}${identifierQuery}${nsecQuery}authType=${type}`, { replace: true });
	};






	const sendCode = async () => {
		if (!isValidEmail(email)) {
			return toast.error(<Toast title='The email is invalid' message='Input a valid email' />);
		}
		if (authType === AuthType.LOGIN) {
			const res = await client.Login({ email, request_token: requestToken });
			if (res.status === "ERROR") {
				return toast.error(<Toast title='Login Error' message={res.reason} />);
			}
			navigate("/verify", { state: { requestToken, authType, submitToken: res.submit_token } });
		} else {
			const res = await client.SignUpWithEmail({ email, identifier, request_token: requestToken, nostr_secret: nsec });
			if (res.status === "ERROR") {
				return toast.error(<Toast title='Login Error' message={res.reason} />);
			}
			navigate("/verify", { state: { requestToken, authType, submitToken: res.submit_token } });
		}
	}

	return (
		<div className='container'>
			<div className='home'>
				<div className='page-title'>{authType}</div>
				<hr className='hr-line' />
				<div className='emailed'>
					<p className='content-title'>Get an auth code emailed to you</p>
					<div className='input-email'>
						<input type='text' placeholder='Email address' onChange={e => setEmail(e.target.value)} />
					</div>
					<div className='send-btn'>
						<button onClick={sendCode} >Send Code</button>
						<span>
							{authType === AuthType.SIGNUP ? "Already have an account?" : "New to Sanctum?"}&nbsp;
							<span className='marked' onClick={() => handleAuthTypeChange(authType === AuthType.SIGNUP ? AuthType.LOGIN : AuthType.SIGNUP)}>
								{authType === AuthType.SIGNUP ? AuthType.LOGIN : AuthType.SIGNUP}
							</span>
						</span>
					</div>
				</div>
				<div className='hr-line-text'>or</div>
				<div className='ask-isNSEC'><Link className='marked' to='/nsec'>Already have an NSEC for Nostr?</Link></div>
			</div>
		</div>
	);
}

export default Authenticate;