import { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";

interface Props {
	ua: string
}
const UserAgentIcons = ({ ua }: Props) => {
	const [uaInfo, setUaInfo] = useState({
		browserName: "",
		osName: ""
	});

	useEffect(() => {
		const parser = new UAParser(ua);
		const osName = parser.getOS().name;
		const browserName = parser.getBrowser().name
		setUaInfo({
			browserName: browserName ? browserName.toLowerCase() : "",
			osName: osName ? osName.toLowerCase() : ""

		})
	}, [])
	return (
		<div style={{ display: "inline-flex", alignItems: "center", padding: "10px" }}>
			{
				uaInfo.osName ? <Icon src={`/${uaInfo.osName}.png`} alt={`${uaInfo.osName} icon`} /> : <span>Unknown</span>
			}
			{
				uaInfo.browserName ? <Icon src={`/${uaInfo.browserName}.png`} alt={`${uaInfo.browserName} icon`} /> : <span>Unknown</span>
			}
		</div>
	)
}

const Icon = ({ src, alt }: { src: string, alt: string }) => <img src={src} alt={alt} style={{ width: "20px", height: "20px", margin : "5px 5px"}} />;

export default UserAgentIcons;