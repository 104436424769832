import Client from './codegen/http_client'
const AUTH_TOKEN_KEY = 'auth_token'
const SESSION_TOKEN_KEY = "SESSION";

export const setAuthToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}
if (!process.env.REACT_APP_BASE_URL) {
    throw new Error("env not set")
}
export default Client({
    baseUrl: process.env.REACT_APP_BASE_URL!,
    retrieveUserAuth: async () => { return getSessionToken() },
    retrieveGuestAuth: async () => { return "" },
    retrieveAccessTokenAuth: async () => { throw new Error("not configured") },
    decryptCallback: async () => { throw new Error("not configured") },
    encryptCallback: async () => { throw new Error("not configured") },
    deviceId: ""
})

export const getSessionToken = () => {
    return localStorage.getItem(SESSION_TOKEN_KEY);
}

export const setSessionToken = (session: string) => {
    localStorage.setItem(SESSION_TOKEN_KEY, session);
}

export const clearSession = () => {
    localStorage.removeItem(SESSION_TOKEN_KEY);
}