import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../redux";
import { toast } from "react-toastify";
import { clear } from "../redux/userSlice";

const useAuth = (needAuth: boolean) => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const { session } = useSelector(state => state.user)

	const goToAuth = useCallback(() => {
		toast.error("Please log in");
		dispatch(clear());
		navigate("/authenticate?authType=Log In");
	}, [navigate, dispatch])

	useEffect(() => {
		if (needAuth) {
			if (!session) {
				goToAuth()
			}
		} else {
			if (session) {
				navigate("/manage")
			}
		}
	}, [session, navigate, goToAuth, needAuth]);

};

export default useAuth;