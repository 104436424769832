import * as Type from './type';
import '../../style_sheets/toast.scss';


const Toast = ({ title, message } : Type.Props) => {
	return (
		<div className='toast-container'>
			<span className='title'>
				{title}
			</span>
			<span className='message'>
				{message}
			</span>
		</div>
	)
}

export default Toast;