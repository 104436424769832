import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerAction } from ".";
import client, { clearSession, setSessionToken } from "../client";
import * as Types from "../codegen/types";




interface UserInfo {
	accessTokens: Types.AccessTokenInfo[];
	keySlots: Record<string, number>
}
export interface User {
	session: string;
	userInfo: UserInfo;
};

const initialState: User = {
	session: "",
	userInfo: {
		accessTokens: [],
		keySlots: {}
	}
}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		updateSession: (state, action: PayloadAction<string>) => {
			setSessionToken(action.payload);
			state.session = action.payload;
		},
		updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
			state.userInfo = action.payload
		},
		clear: (state) => {
			state.session = "";
			clearSession();
			state.userInfo = {
				accessTokens: [],
				keySlots: {}
			};
		}
	}
});



export const { updateSession, updateUserInfo, clear } = userSlice.actions;


export default userSlice.reducer;

export const fetchUserData = (): ReducerAction => async dispatch => {
	const res = await client.GetUserInfo();
	if (res.status === "ERROR") {
		dispatch(clear());
		return
	}


	dispatch(updateUserInfo({ keySlots: res.key_slots, accessTokens: res.user_access_tokens }));
};