import { DisplayEvent } from "../../utils/types";
import { truncateStringMiddle } from "../../utils/utility";
import styles from "./index.module.scss";

interface Props {
	events: DisplayEvent[]
}
const Events = ({ events }: Props) => {
	return (

		<div className={styles["events-container"]}>
			{
				events.map(e => (
					<div className={styles["event-row"]} key={`${e.type}${e.datestring}${e.nevent}`}>
						<span>{e.datestring}&nbsp;|</span>
						<span>{e.type}&nbsp;{truncateStringMiddle(e.nevent)}|</span>
						<span>&nbsp;<img src={e.flagSrc} style={{ width: "15px", aspectRatio: "16/9" }} alt={e.flagAlt} />&nbsp;{e.state}</span>
					</div>
				))
			}
		</div>
	)
}

export default Events;