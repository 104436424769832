import React, { useEffect, useState } from 'react';
import { ServiceCard, ServiceDetailsCard } from '../../components/serviceCard';
import Client from '../../client';
import * as Types from '../../globalTypes';
import * as Icons from '../../iconLibrary/svgIconLibrary';
import '../../style_sheets/nsec.scss';

const Nsec: React.FC = () => {
    const [selectedService, setSelectedService] = useState<Types.ServiceCardProps | string>('');

    const serviceCards: Types.ServiceCardProps[] = [
        {
            id: 0,
            icon: Icons.cloud(),
            name: 'Basic',
            price: 'Free',
            description: ['Single Nostr Identity', 'Email Authentication', 'For Verified Apps']
        },
        {
            id: 1,
            icon: Icons.cloud(),
            name: 'Pro',
            price: '21k sats',
            description: ['Up to 3 Nostr Identities', 'MFA/2FA Authentication', 'For Any Compatible App', 'Priority Support & Early Features', 'Invites for 2 Extra Users'],
            status: 'Coming Soon'
        },
        {
            id: 2,
            icon: Icons.cloud(),
            name: 'Team',
            price: '210k sats',
            description: ['Up to 3 Nostr Identities per User', 'Identity Sharing / Delegation', 'MFA/2FA Authentication', 'For Any Compatible App', 'Priority Support & Early Features', 'Invites for 10 Extra Users'],
            status: 'Coming Soon'
        },
        {
            id: 3,
            icon: Icons.home(),
            name: 'Sovereign',
            price: '61.5k sats',
            description: ['Connect a Self-Hosted Signer', 'for up to 10 Nostr Identities', 'Nostr-only Authentication', 'For Any Compatible App', 'Priority Support & Early Features'],
            status: 'Coming Soon'
        }
    ]

    useEffect(() => {
        Client.Health().then(console.log)
    }, [])

    const selectService = (card: Types.ServiceCardProps) => {
        setSelectedService(card);
    }

    return (
        <div className='container'>
            <div className='nsec'>
                <div className='page-title'>Log-In with NSEC</div>
                {
                    selectedService === ''
                        ?
                        <>
                            <div className='page-description'>
                                <div>
                                    Sanctum offers more capable and secure remote signing for Nostr, without the need for a browser extension.
                                </div><br />
                                <div className='input'>
                                    <div>Import a private key:</div>
                                    <input type='password' placeholder='Enter NSEC' onChange={e => console.log()} />
                                    <div className='small'>* To self-host a signer without providing an nsec, choose sovereign below.</div>
                                </div>
                            </div>

                            <div className='title content-title'>Choose a service level</div>
                            <div className='subtitle'>Tap to select and show details</div>
                        </>
                        :
                        <ServiceDetailsCard selectedService={selectedService} close={() => setSelectedService('')} />
                }

                <div className='choose-service'>
                    <div className='service-card-group'>
                        {serviceCards.map((card, i) => <ServiceCard key={i} {...card} selectService={() => selectService(card)} />)}
                    </div>
                    <div className='tips marked'>Subscription prices are one-time, for life!</div>
                </div>

                <div className='continue-btn'>
                    <button onClick={() => console.log('continue...')}>Continue</button>
                </div>
            </div>
        </div>
    );
}

export default Nsec;
