import React from "react";
import { Link } from "react-router-dom";
import "../../style_sheets/learn.scss";
import "../../style_sheets/header.scss";
import * as Icons from "../../iconLibrary/svgIconLibrary";

const LearnMore: React.FC = () => {
  return (
    <div className="learn">
      <div className="learn-content">
        <div className="page-title">Nostr Identity Solved</div>
        <div className="page-description">
          <div className=" element-center">
            Sanctum provides everything you need to secure and manage Nostr
            identity keys.
          </div>
        </div>
        <div className="management-container element-center">
          <div className="management-group">
            <div className="element-start-center">
              {Icons.easy_cloud()}
              <div>Easy Cloud Management</div>
            </div>
            <div className="element-start-center">
              {Icons.delegation()}
              <div>Team Delegation</div>
            </div>
            <div className="element-start-center">
              {Icons.device()}
              <div>Device Level Auditing</div>
            </div>
            <div className="element-start-center">
              {Icons.security()}
              <div>Unrivaled Security</div>
            </div>
            <div className="element-start-center">
              {Icons.turstless()}
              <div>Trustless Optionality</div>
            </div>
          </div>
          <div className="management-image">
            <img src="/token-management.png" alt="token-management" />
          </div>
        </div>
        <div className="signup-btn element-center">
          <button>
            <Link to="/authenticate">Sign Up</Link>
          </button>
        </div>
        <div className="builders element-center">
          <div className="builder-box">
            <div>
              <span>Hey Builders,</span>
            </div>
            <div className="element-center">{Icons.builder()}</div>
            <div className="element-center">Deploy faster,</div>
            <div className="element-center">
              protect your users, and your reputation,
            </div>
            <div className="element-center">
              with our&nbsp;<span>NIP07 auth widget</span>.
            </div>
          </div>
        </div>
        <div className="contact-us element-center">
          <a
            className="contact-us-link"
            href="https://github.com/orgs/shocknet/discussions"
            target="_blank"
          >
            Contact Us 🤙
          </a>
        </div>
        <div className="basically">
          <div className="basically-title">How it works, basically,</div>
          <div className="element-center">
            <div className="basically-box element-center">
              <div className="box-content">
                <div>App widget requests access</div>
                <div>{Icons.request_access()}</div>
                <div>User approves prompt</div>
                <div>{Icons.prompt()}</div>
                <div>Signer issues access token</div>
                <div>{Icons.signer()}</div>
                <div>Token requests operations</div>
                <div>{Icons.operations()}</div>
                <div>Sanctum verifies, signer emits the event</div>
                <div>{Icons.singer_event()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="private-text">
          Self-host your signer, or use our private cloud.
        </div>
      </div>
      <div className="learn-footer">
        <div className="shocknet element-center">
          <img src="/shocknet.png" alt="shocknet" />
        </div>
        <div className="footer-text element-center">
          <span>
            <a href="https://docs.shock.network" target="_blank">
              Docs
            </a>
          </span>
          &nbsp;and&nbsp;
          <span>
            <a href="https://docs.shock.network" target="_blank">
              Terms
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
