import { useEffect } from "react";
import { useDispatch, useSelector } from "../../redux";
import { fetchUserData } from "../../redux/userSlice";

export default function GlobalStatesContainer({
  children,
}: {
  children: JSX.Element;
}) {
  const { session } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (session) {
      dispatch(fetchUserData());
    }
  }, [dispatch, session]);

  return <>{children}</>;
}
