import React from 'react';
import { NavigateFunction, useNavigate, Link } from "react-router-dom";
import { formatDisplayEmail } from "../../utils/utility";
import '../../style_sheets/header.scss';

export const Header: React.FC = () => {
    const navigate: NavigateFunction = useNavigate();
    const isSigned: boolean = false
    const userEmail: string = 'authuser@domain.tld' 

    return (
        <div className='header'>
            <div onClick={() => navigate('/')}>
                <img src='/logo.png' height={20} alt='logo' />
            </div>
            <div className="signin-btn">
                {isSigned ? formatDisplayEmail(userEmail) : <Link to="/verify" className="element-center">
                    Sign in
                </Link>}
            </div>
        </div>
    )
}
