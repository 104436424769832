import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Layout from "./layout";
import App from "./pages/home";
import Verify from "./pages/verify";
import Confirmation from "./pages/confirmation";
import Nsec from "./pages/nsec";
import CreateKey from "./pages/createKey";
import "./style_sheets/global.scss";
import "typeface-montserrat";
import "react-toastify/dist/ReactToastify.css";
import Manage from "./pages/manage";
import Authenticate from "./pages/authenticate";
import { store, persistor } from "./redux/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GlobalStatesContainer from "./components/globalStatesContainer";
import Events from "./pages/Events";
import LearnMore from "./pages/learn";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <GlobalStatesContainer>
        <BrowserRouter>
          <Routes>
            {/* Routes with Layout */}
            <Route path="/" element={<Layout />}>
              <Route index element={<App />} />
              <Route path="authenticate" element={<Authenticate />} />
              <Route path="confirmation" element={<Confirmation />} />
              <Route path="manage" element={<Manage />} />
              <Route path="nsec" element={<Nsec />} />
              <Route path="verify" element={<Verify />} />
              <Route path="createKey" element={<CreateKey />} />
              <Route path="events" element={<Events />} />
              <Route path="/learn" element={<LearnMore />} />
            </Route>
          </Routes>
          <ToastContainer
            position="top-center"
            closeOnClick
            pauseOnHover
            autoClose={4000}
            limit={2}
            pauseOnFocusLoss={false}
          />
        </BrowserRouter>
      </GlobalStatesContainer>
    </PersistGate>
  </Provider>
);
