import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom';
import VerifyInput from 'react-verification-input';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import Client from '../../client';

import * as Type from './type';
import * as Icon from '../../iconLibrary/svgIconLibrary';
import { formatTime } from '../../utils/utility';
import '../../style_sheets/verify.scss';
import { updateSession } from '../../redux/userSlice';
import { useDispatch } from '../../redux';
import { AuthType } from '../authenticate';

const startTimerValue = 15 * 60;

const Verify: React.FC = () => {
	const navigate: NavigateFunction = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();
	const { email, submitToken, authType, requestToken } = location.state || {};

	const [verifyCode, setVerifyCode] = useState('');
	const [seconds, setSeconds] = useState(startTimerValue);
	const [isnewUser, setIsnewUser] = useState(false);



	useEffect(() => {
		if (!submitToken) {
			return navigate(-1);
		}

		setIsnewUser(authType === "Sign Up")

		const timer = setInterval(() => {
			setSeconds(prevSeconds => prevSeconds - 1);
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	// useEffect(() => {
	// 	if (seconds === 0) {
	// 		toast.warn(<Toast title='Verification code has been expired.' message='Try again.' />)
	// 		return navigate(-1);
	// 	}
	// }, [seconds, navigate]);

	const submitVerify = async () => {
		if (verifyCode.length !== 6) {
			return toast.error(<Toast title='Code is invalid' message='Input a 6 digit code.' />);
		}
		const res = await Client.RequestCallback({ submit_token: submitToken!, otp: verifyCode })

		if (res.status === "ERROR") {
			return toast.error(<Toast title='Code is invalid' message={res.reason} />);
		}
		dispatch(updateSession(res.session_token));

		if (requestToken && authType === AuthType.LOGIN) {
			navigate('/confirmation', {
				state: {
					requestToken,
				}
			});
		} else {
			toast.success(<Toast title='Success' message="Successfuly auth'd" />);
			navigate('/manage')
		}
	}


	const newUserDescription = (
		<React.Fragment>
			<div>
				<b>Welcome!</b> It looks like you're new here.<br />
				<br />Please enter the 6 digit code we sent to <b>{email}</b> to get verified.
			</div>
			<div className='marked'>Need help?</div>
		</React.Fragment>
	);

	const existingUser = (
		<React.Fragment>
			<div>
				<b>Welcome back!</b> It looks like you're logging in from a new device.<br />
				<br />Please enter the 6 digit code we sent to <b>{email}</b>
			</div>
			<div className='marked'>Need help?</div>
		</React.Fragment>
	);

	const changeDescription = () => {
		setIsnewUser(!isnewUser);
	};

	const clickResend = () => {
		navigate('/nsec');
	}

	return (
		<div className='container'>
			<div className='verify'>
				<div className='page-title'>Verification</div>
				<div className='page-description' onClick={changeDescription}>
					{isnewUser ? newUserDescription : existingUser}
				</div>
				<hr className='hr-line' />
				<div className='verify-code'>
					<div className='input'>
						<VerificationInput
							onChange={(e) => setVerifyCode(e)}
							length={6}
							validChars='0-9'
							removeDefaultStyles
							container={{
								className: 'container'
							}}
							characters={{
								className: 'characters'
							}}
							character={{
								className: 'character',
								classNameInactive: 'character--inactive',
								classNameSelected: 'character--selected'
							}}
						/>
					</div>
					<div className='timer'>
						{Icon.sandClock()}
						<div className='timer-num'>{formatTime(seconds)}</div>
					</div>
				</div>
				<div className='verify-btn-group'>
					<div className='send-code-btn'>
						<button onClick={submitVerify}>Submit</button>
					</div>
					<div
						className='resend-code-btn marked'
					>
						<span 
							onClick={clickResend}
						>Resend
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

const VerificationInput: React.FC<Type.VerificationInputProps> = (props) => {
	return (
		<VerifyInput {...props} />
	);
}

export default Verify;
