import React, { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import "../../style_sheets/home.scss";
import { useSelector } from "../../redux";
import useAuth from "../../hooks/useAuth";
import useEventsInfiniteScroll from "../../hooks/useEventsInfiniteLoader";
import InfiniteLoader from "../../components/InfiniteLoader";
import EventsComponenet from "../../components/Events";

const Events: React.FC = () => {
  useAuth(true);

  const location = useLocation();
  const { accessToken } = location.state || {};
  console.log(accessToken);

  const { isLoading, loadMoreCallback, dynamicEvents, isLastPage } =
    useEventsInfiniteScroll(10, accessToken);

  return (
    <>
      <EventsComponenet events={dynamicEvents} />
      <InfiniteLoader
        isLoading={isLoading}
        isLastPage={isLastPage}
        loadMoreCallback={loadMoreCallback}
      />
    </>
  );
};

export default Events;
